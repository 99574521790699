export const otp = {
  get: {
    url: "otps/get",
    method: "GET",
    id: null,
    data: null,
  },
  delete: {
    url: "otp/delete/",
    method: "DELETE",
    id: null,
  },
};
